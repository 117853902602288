/**
 * @fileoverview This file was generated by the Angular template compiler. Do not edit.
 *
 * @suppress {suspiciousCode,uselessCode,missingProperties,missingOverride,checkTypes}
 * tslint:disable
 */ 
import * as i0 from "./header.component.scss.shim.ngstyle";
import * as i1 from "@angular/core";
import * as i2 from "../../../pipes/phone.pipe";
import * as i3 from "@ng-bootstrap/ng-bootstrap";
import * as i4 from "@angular/router";
import * as i5 from "@angular/common";
import * as i6 from "ng-lazyload-image";
import * as i7 from "./header.service";
import * as i8 from "@angular/common/http";
import * as i9 from "./header.component";
import * as i10 from "@angular/material/dialog";
import * as i11 from "../../../../storage/universal.inject";
import * as i12 from "@angular/platform-browser";
import * as i13 from "../../../../shared/shared-data.service";
import * as i14 from "../../../services/tracking.service";
var styles_HeaderComponent = [i0.styles];
var RenderType_HeaderComponent = i1.ɵcrt({ encapsulation: 0, styles: styles_HeaderComponent, data: {} });
export { RenderType_HeaderComponent as RenderType_HeaderComponent };
function View_HeaderComponent_1(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 11, "div", [["class", "phone-number-header"]], null, null, null, null, null)), (_l()(), i1.ɵeld(1, 0, null, null, 0, "i", [["class", "call-icon d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(2, 0, null, null, 5, "div", [["class", "phone-info d-none d-md-block"]], null, null, null, null, null)), (_l()(), i1.ɵeld(3, 0, null, null, 1, "span", [], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Call For Exclusive Deals"])), (_l()(), i1.ɵeld(5, 0, null, null, 2, "a", [], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵted(6, null, ["", ""])), i1.ɵppd(7, 1), (_l()(), i1.ɵeld(8, 0, null, null, 3, "a", [["class", "d-md-none visible-phone d-flex align-items-center"]], [[8, "href", 4]], null, null, null, null)), (_l()(), i1.ɵeld(9, 0, null, null, 0, "i", [["class", "call-icon"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 1, "b", [["class", "call-txt"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Call Now"]))], null, function (_ck, _v) { var _co = _v.component; var currVal_0 = i1.ɵinlineInterpolate(1, "tel:", _co.phone, ""); _ck(_v, 5, 0, currVal_0); var currVal_1 = i1.ɵunv(_v, 6, 0, _ck(_v, 7, 0, i1.ɵnov(_v.parent, 0), _co.phone)); _ck(_v, 6, 0, currVal_1); var currVal_2 = i1.ɵinlineInterpolate(1, "tel:", _co.phone, ""); _ck(_v, 8, 0, currVal_2); }); }
export function View_HeaderComponent_0(_l) { return i1.ɵvid(2, [i1.ɵpid(0, i2.PhonePipe, []), i1.ɵqud(671088640, 1, { navMenuCheckbox: 0 }), (_l()(), i1.ɵeld(2, 0, null, null, 46, "nav", [["class", "main-block navbar navbar-expand"]], null, null, null, null, null)), i1.ɵdid(3, 16384, null, 0, i3.ɵm, [], null, null), (_l()(), i1.ɵeld(4, 0, null, null, 44, "div", [["class", "container"]], null, null, null, null, null)), (_l()(), i1.ɵeld(5, 0, null, null, 3, "a", [["class", "navbar-brand-logo"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 6).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(6, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(7, 0, null, null, 1, "img", [["alt", "protravelworld.com"]], null, null, null, null, null)), i1.ɵdid(8, 1720320, null, 0, i6.LazyLoadImageDirective, [i1.ElementRef, i1.NgZone, [2, "options"]], { lazyImage: [0, "lazyImage"] }, null), (_l()(), i1.ɵeld(9, 0, null, null, 0, "div", [["class", "ae"]], null, null, null, null, null)), (_l()(), i1.ɵeld(10, 0, null, null, 38, "div", [["class", "collapse navbar-collapse"]], null, null, null, null, null)), (_l()(), i1.ɵand(16777216, null, null, 1, null, View_HeaderComponent_1)), i1.ɵdid(12, 16384, null, 0, i5.NgIf, [i1.ViewContainerRef, i1.TemplateRef], { ngIf: [0, "ngIf"] }, null), (_l()(), i1.ɵeld(13, 0, null, null, 2, "div", [["class", "call-me-button"]], null, null, null, null, null)), (_l()(), i1.ɵeld(14, 0, null, null, 1, "button", [["class", "btn"], ["type", "button"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.openCallMeDialog() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵted(-1, null, [" Call Back "])), (_l()(), i1.ɵeld(16, 0, [[1, 0], ["navMenuCheckbox", 1]], null, 0, "input", [["hidden", ""], ["id", "nav-right-toggle"], ["type", "checkbox"]], null, null, null, null, null)), (_l()(), i1.ɵeld(17, 0, null, null, 3, "label", [["class", "nav-right-toggle"], ["for", "nav-right-toggle"]], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.showMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵprd(512, null, i5.ɵNgClassImpl, i5.ɵNgClassR2Impl, [i1.IterableDiffers, i1.KeyValueDiffers, i1.ElementRef, i1.Renderer2]), i1.ɵdid(19, 278528, null, 0, i5.NgClass, [i5.ɵNgClassImpl], { klass: [0, "klass"], ngClass: [1, "ngClass"] }, null), i1.ɵpod(20, { "right-align": 0 }), (_l()(), i1.ɵeld(21, 0, null, null, 26, "div", [["class", "nav-right"]], null, null, null, null, null)), (_l()(), i1.ɵeld(22, 0, null, null, 25, "ul", [], null, [[null, "click"]], function (_v, en, $event) { var ad = true; var _co = _v.component; if (("click" === en)) {
        var pd_0 = (_co.hideMenu() !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), (_l()(), i1.ɵeld(23, 0, null, null, 4, "li", [["itemscope", "itemscope"], ["itemtype", "http://www.schema.org/SiteNavigationElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(24, 0, null, null, 3, "a", [["itemprop", "url"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 25).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(25, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(26, 0, null, null, 1, "span", [["itemprop", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Flights"])), (_l()(), i1.ɵeld(28, 0, null, null, 4, "li", [["itemscope", "itemscope"], ["itemtype", "http://www.schema.org/SiteNavigationElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(29, 0, null, null, 3, "a", [["itemprop", "url"], ["routerLink", "/cruises"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 30).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(30, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(31, 0, null, null, 1, "span", [["itemprop", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Cruises"])), (_l()(), i1.ɵeld(33, 0, null, null, 4, "li", [["itemscope", "itemscope"], ["itemtype", "http://www.schema.org/SiteNavigationElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(34, 0, null, null, 3, "a", [["itemprop", "url"], ["routerLink", "our-team"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 35).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(35, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(36, 0, null, null, 1, "span", [["itemprop", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Meet Our Team"])), (_l()(), i1.ɵeld(38, 0, null, null, 4, "li", [["itemscope", "itemscope"], ["itemtype", "http://www.schema.org/SiteNavigationElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(39, 0, null, null, 3, "a", [["itemprop", "url"], ["routerLink", "about-us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 40).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(40, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(41, 0, null, null, 1, "span", [["itemprop", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["About us"])), (_l()(), i1.ɵeld(43, 0, null, null, 4, "li", [["itemscope", "itemscope"], ["itemtype", "http://www.schema.org/SiteNavigationElement"]], null, null, null, null, null)), (_l()(), i1.ɵeld(44, 0, null, null, 3, "a", [["itemprop", "url"], ["routerLink", "contact-us"]], [[1, "target", 0], [8, "href", 4]], [[null, "click"]], function (_v, en, $event) { var ad = true; if (("click" === en)) {
        var pd_0 = (i1.ɵnov(_v, 45).onClick($event.button, $event.ctrlKey, $event.metaKey, $event.shiftKey) !== false);
        ad = (pd_0 && ad);
    } return ad; }, null, null)), i1.ɵdid(45, 671744, null, 0, i4.RouterLinkWithHref, [i4.Router, i4.ActivatedRoute, i5.LocationStrategy], { routerLink: [0, "routerLink"] }, null), (_l()(), i1.ɵeld(46, 0, null, null, 1, "span", [["itemprop", "name"]], null, null, null, null, null)), (_l()(), i1.ɵted(-1, null, ["Contact Us"])), (_l()(), i1.ɵeld(48, 0, null, null, 0, "div", [["class", "mask-content"]], null, null, null, null, null))], function (_ck, _v) { var _co = _v.component; var currVal_2 = i1.ɵinlineInterpolate(1, "", (_co.isBusinessLand ? "/home" : "/first-class"), ""); _ck(_v, 6, 0, currVal_2); var currVal_3 = (_co.cdnPath + "/assets/img/logo.png"); _ck(_v, 8, 0, currVal_3); var currVal_4 = _co.phoneVisible; _ck(_v, 12, 0, currVal_4); var currVal_5 = "nav-right-toggle"; var currVal_6 = _ck(_v, 20, 0, !_co.phoneVisible); _ck(_v, 19, 0, currVal_5, currVal_6); var currVal_9 = i1.ɵinlineInterpolate(1, "", _co.buildLink("flights", _co.isBusinessLand), ""); _ck(_v, 25, 0, currVal_9); var currVal_12 = "/cruises"; _ck(_v, 30, 0, currVal_12); var currVal_15 = "our-team"; _ck(_v, 35, 0, currVal_15); var currVal_18 = "about-us"; _ck(_v, 40, 0, currVal_18); var currVal_21 = "contact-us"; _ck(_v, 45, 0, currVal_21); }, function (_ck, _v) { var currVal_0 = i1.ɵnov(_v, 6).target; var currVal_1 = i1.ɵnov(_v, 6).href; _ck(_v, 5, 0, currVal_0, currVal_1); var currVal_7 = i1.ɵnov(_v, 25).target; var currVal_8 = i1.ɵnov(_v, 25).href; _ck(_v, 24, 0, currVal_7, currVal_8); var currVal_10 = i1.ɵnov(_v, 30).target; var currVal_11 = i1.ɵnov(_v, 30).href; _ck(_v, 29, 0, currVal_10, currVal_11); var currVal_13 = i1.ɵnov(_v, 35).target; var currVal_14 = i1.ɵnov(_v, 35).href; _ck(_v, 34, 0, currVal_13, currVal_14); var currVal_16 = i1.ɵnov(_v, 40).target; var currVal_17 = i1.ɵnov(_v, 40).href; _ck(_v, 39, 0, currVal_16, currVal_17); var currVal_19 = i1.ɵnov(_v, 45).target; var currVal_20 = i1.ɵnov(_v, 45).href; _ck(_v, 44, 0, currVal_19, currVal_20); }); }
export function View_HeaderComponent_Host_0(_l) { return i1.ɵvid(0, [(_l()(), i1.ɵeld(0, 0, null, null, 2, "app-header", [], null, null, null, View_HeaderComponent_0, RenderType_HeaderComponent)), i1.ɵprd(512, null, i7.HeaderService, i7.HeaderService, [i8.HttpClient]), i1.ɵdid(2, 4308992, null, 0, i9.HeaderComponent, [i10.MatDialog, i11.AppStorage, i8.HttpClient, i12.TransferState, i7.HeaderService, i4.Router, i13.DataService, i14.TrackingService], null, null)], function (_ck, _v) { _ck(_v, 2, 0); }, null); }
var HeaderComponentNgFactory = i1.ɵccf("app-header", i9.HeaderComponent, View_HeaderComponent_Host_0, {}, {}, []);
export { HeaderComponentNgFactory as HeaderComponentNgFactory };
