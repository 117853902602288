<section id="services">
    <div class="container col-xl-12 px-3 py-4">
  
      <div class="row featurette align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left">
          <p class="featurette-heading"><span>Save On</span> Business Class Flights And Cruises</p> 
          <p class="lead">
            Utilizing our robust partnerships with leading consolidators and direct access to unpublished rates, private fares, and enticing promotional deals, we can provide the most competitive pricing for international flights and luxury cruises.</p>
          <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Get a FREE Quote</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/01.png" alt="Services" aria-hidden="true"/></div>
      </div>
    
      <div class="row featurette pt-5 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 order-lg-3 text-center text-lg-left">
          <p class="featurette-heading">How Much Will <span>I Save?</span></p>
          <p class="lead">
            The cost varies based on factors like your travel destinations, dates, and availability. Typically, we can slash prices by 20-50% below retail, and at times, the savings are even more significant. Reach out for a quote today, and we'll promptly furnish you with the details.</p>
          <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Get a FREE Quote</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/02.png" alt="Services" aria-hidden="true"/></div>
      </div>
  
      <div class="row featurette pt-5 align-items-center justify-content-center">
        <div class="col-sm-12 col-md-12 col-lg-6 col-xl-5 text-center text-lg-left">
          <p class="featurette-heading">Never Wait On Hold Again <span>A Dedicated Travel Specialist</span></p>
          <p class="lead">
            Rely on your dedicated travel specialist to handle every detail, from securing preferred seating and catering to specific meal preferences, to arranging travel insurance and maximizing mileage rewards. Our expertise is centered on finding solutions that not only save you time but also save you money!</p>
          <a class="btn btn-primary mb-3 bth-primary" (click)="openCallMeDialog()">Request a Consultation</a>
        </div>
        <div class="col-sm-12 col-md-12 col-lg-6"><img class="select-box__icon" src="assets/img/services/flights/03.png" alt="Services" aria-hidden="true"/></div>
      </div>
  
    </div>
  </section>
