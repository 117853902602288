import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import eq from 'lodash-es/eq';
import {Router} from '@angular/router';
import { RecaptchaComponent } from "ng-recaptcha";

import {ContactData} from '../../../model/contact-data';
import {NotifyService} from '../../../services/notify.service';
import { CallMeHttpService } from './call-me-http.service';

@Component({
  selector: 'app-call-me-dialog',
  templateUrl: './call-me-dialog.component.html',
  styleUrls: ['./call-me-dialog.component.scss', './../../../../styles/_modal.scss']
})
export class CallMeDialogComponent implements OnInit {
  @ViewChild('recaptchaElement', { static: false }) recaptchaElement: RecaptchaComponent;
  callMeForm: FormGroup;
  callMeData: ContactData = new ContactData();
  phoneMask = ['(', /[1-9]/, /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];
  public isRecaptchaResolved: boolean = false;

  constructor(private formBuilder: FormBuilder,
              private dialogRef: MatDialogRef<CallMeDialogComponent>,
              private callMeHttpService: CallMeHttpService,
              @Inject(MAT_DIALOG_DATA) public data: any,
              private router: Router,
              private notifyService: NotifyService) {
  }

  ngOnInit() {
    this.initCallMeForm();
  }

  initCallMeForm() {
    this.callMeForm = this.formBuilder.group({
      firstName: ['', Validators.required],
      phone: ['', Validators.compose([Validators.required, Validators.pattern('^[(]\\d{3}[)][\\s]\\d{3}[-]\\d{4}$')])],
      email: ['', Validators.compose([Validators.required, Validators.email])]
    });
  }

  onCloseDialog(): void {
    this.dialogRef.close();
  }

  callMe() {
    if (this.callMeForm.valid && this.isRecaptchaResolved === true) {
      this.callMeHttpService.callMe(this.callMeData).subscribe(
        response => {
          if (eq(response.status, 'FAILED')) {
            this.notifyService.error('Can\'t process your request now. Please try later.', {
              closeWith: ['click', 'button'],
              timeout: 10000
            });
          } else {
            this.onCloseDialog();
            this.router.navigateByUrl('/your-request');
          }
        },
      );
    }
  }

  public resolved(captchaResponse: string): void {
    this.isRecaptchaResolved = !!captchaResponse;
  }
}
