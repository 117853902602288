import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {RestResponse} from '../../../model/rest-response';
import {environment} from '../../../../environments/environment';
import {HttpClient} from '@angular/common/http';

@Injectable()
export class HomeService {

    constructor(private http: HttpClient) {
    }

    getPopular(type: string): Observable<RestResponse> {
        return this.http.get<RestResponse>(environment.serverUrl + '/custom-page/get-by-type-and-popular', {
            params: {
                'type': type,
                'popular': 'true',
                'site': 'ptw'
            }
        });
    }
}
