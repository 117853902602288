<!-- Modal Reviews-->
<div class="modal-open  call-me-modal">
  <div class=" modal  show bd-example-modal-lg" id="reviewsModal" tabindex="-1" role="dialog" aria-hidden="true" style="display: block;">
    <div class="modal-dialog modal-lg" role="document">
      <div class="modal-content">
        <div class="modal-header">
          <h2 class="modal-title">We love feedback!</h2>
          <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="onCloseDialog()">
            <span aria-hidden="true">&times;</span>
          </button>
        </div>
        <form *ngIf="!reviewFormSending" id="writeReviewForm" name="writeReviewForm" [formGroup]="writeReviewForm" #reviewForm="ngForm" novalidate>
        <div class="modal-body">
          <div *ngIf="!userId" class="row">
            <div class="col">
              <label for="">Booking Agent</label>
              <select class="form-control" formControlName="agent" [(ngModel)]="reviewData.agent"
                      [ngClass]="{'has-error': writeReviewForm.get('agent').invalid && reviewForm.submitted}">
                <option *ngFor="let user of reviewUsers" [ngValue]="user.id">
                  {{ user.fullName }}
                </option>
              </select>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="">Your Name</label>
              <input [ngClass]="{'has-error': writeReviewForm.get('name').invalid && reviewForm.submitted}"
                     type="name" class="form-control" placeholder="Name" formControlName="name" [(ngModel)]="reviewData.name">
            </div>
            <div class="col">
              <label for="">Rating</label>
              <div class="rate" [ngClass]="{'has-error': writeReviewForm.get('rating').invalid && reviewForm.submitted}">
                <input type="radio" id="star5" name="rating" value="5" formControlName="rating" />
                <label for="star5" title="text">5 stars</label>
                <input type="radio" id="star4" name="rating" value="4" formControlName="rating" />
                <label for="star4" title="text">4 stars</label>
                <input type="radio" id="star3" name="rating" value="3" formControlName="rating" />
                <label for="star3" title="text">3 stars</label>
                <input type="radio" id="star2" name="rating" value="2" formControlName="rating" />
                <label for="star2" title="text">2 stars</label>
                <input type="radio" id="star1" name="rating" value="1" formControlName="rating" />
                <label for="star1" title="text">1 star</label>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col">
              <label for="">Your Feedback</label>
              <textarea class="form-control" rows="8" placeholder="Review Text" formControlName="reviewText" [(ngModel)]="reviewData.reviewText"
                        [ngClass]="{'has-error': writeReviewForm.get('reviewText').invalid && reviewForm.submitted}" ></textarea>
            </div>
          </div>
          <div class="row">
            <div class="col justify-content-end">
              <button type="submit" class="btn" (click)="submitReview()">Submit</button>
            </div>
          </div>
        </div>
        </form>
        <div *ngIf="reviewFormSending" class="reviewThankText">Thank you, your feedback is greatly appreciated.</div>
      </div>
    </div>
  </div>
</div>
