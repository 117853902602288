import { Router } from '@angular/router';
import * as i0 from "@angular/core";
import * as i1 from "@angular/router";
var TagService = /** @class */ (function () {
    function TagService(router, platformId) {
        this.router = router;
        this.platformId = platformId;
        /*if (isPlatformServer(this.platformId)) {
          return;
        }
    
        const gtag = (<any> window).gtag;
        const bing = (<any> window).bing;
    
        router.events.subscribe(event => {
          if (event instanceof NavigationEnd) {
            /!* Google Analytics pageview event *!/
            gtag('config', 'UA-2039848-1', {'optimize_id': 'GTM-WXLBLTS',
              page_path: event.urlAfterRedirects
            });
            /!* Google AdWords *!/
            gtag('config', 'AW-1061651272');
            console.debug('Visiting ' + event.urlAfterRedirects + ' should be tracked via GTag');
            /!* Bing Ads *!/
            bing();
          }
        });*/
    }
    TagService.ngInjectableDef = i0.ɵɵdefineInjectable({ factory: function TagService_Factory() { return new TagService(i0.ɵɵinject(i1.Router), i0.ɵɵinject(i0.PLATFORM_ID)); }, token: TagService, providedIn: "root" });
    return TagService;
}());
export { TagService };
